import createLucideIcon from '../createLucideIcon';

const FolderArchive = createLucideIcon('FolderArchive', [
  [
    'path',
    {
      d: 'M22 20V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2h6',
      key: '1l0vpk',
    },
  ],
  ['circle', { cx: '16', cy: '19', r: '2', key: '1uwppb' }],
  ['path', { d: 'M16 11v-1', key: 'eoyjtm' }],
  ['path', { d: 'M16 17v-2', key: '1xp69b' }],
]);

export default FolderArchive;

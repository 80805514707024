import createLucideIcon from '../createLucideIcon';

const Folders = createLucideIcon('Folders', [
  [
    'path',
    {
      d: 'M8 17h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3.93a2 2 0 0 1-1.66-.9l-.82-1.2a2 2 0 0 0-1.66-.9H8a2 2 0 0 0-2 2v9c0 1.1.9 2 2 2Z',
      key: '1aska4',
    },
  ],
  ['path', { d: 'M2 8v11c0 1.1.9 2 2 2h14', key: 'n13cji' }],
]);

export default Folders;

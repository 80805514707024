import createLucideIcon from '../createLucideIcon';

const FolderSearch2 = createLucideIcon('FolderSearch2', [
  [
    'path',
    {
      d: 'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      key: '1fr9dc',
    },
  ],
  ['circle', { cx: '11.5', cy: '12.5', r: '2.5', key: '1ea5ju' }],
  ['path', { d: 'M13.27 14.27 15 16', key: '5hsvtf' }],
]);

export default FolderSearch2;

import createLucideIcon from '../createLucideIcon';

const GitFork = createLucideIcon('GitFork', [
  ['circle', { cx: '12', cy: '18', r: '3', key: '1mpf1b' }],
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['circle', { cx: '18', cy: '6', r: '3', key: '1h7g24' }],
  ['path', { d: 'M18 9v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V9', key: 'c89w8i' }],
  ['path', { d: 'M12 12v3', key: '158kv8' }],
]);

export default GitFork;

import createLucideIcon from '../createLucideIcon';

const Globe = createLucideIcon('Globe', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['line', { x1: '2', x2: '22', y1: '12', y2: '12', key: '1dnqot' }],
  [
    'path',
    {
      d: 'M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z',
      key: 'nb9nel',
    },
  ],
]);

export default Globe;
